<template>
  <div class="contain" v-if="platfrom === 'PC'">
    <app-header :currentTab="currentTab" @onChangeTab="onChangeTab" />
    <div v-if="currentTab === 0">
      <slider
        :active="pageName"
        :scrollDirection="scrollDirection"
        @onChangePage="onChangePage"
      ></slider>
      <div class="content">
        <img class="initLeftImg" id="leftImg" src="./assets/left.jpg" />
        <img class="initRightImg" id="rightImg" src="./assets/right.jpg" />
        <div class="page2" id="page2">
          <div class="title">
            <div class="line"></div>
            <div style="letter-spacing: 5px">CONVERGENCE</div>
          </div>
          <div class="text">AI-Enabled Trading Algorithm Platform</div>
          <div class="next">
            <div>scroll down</div>
            <img
              src="./assets/arrow.png"
              style="height: 20px; width: 20px; margin-left: 10px"
              alt=""
            />
          </div>
        </div>
        <div class="page3" id="page3">
          <span>Convergence leverages</span>
          <span style="background-color: #fbd784; color: black"
            >advanced AI</span
          >
          <span>to</span>
          <span style="background-color: #fbd784; color: black"
            >revolutionize trading algorithms,</span
          >
          <span
            >offering a seamless and secure solution for traders seeking to
            maximize profitability while protecting proprietary
            strategies.</span
          >
        </div>
        <div class="line-with-shadows" id="lines">
          <div class="leftCircle"></div>
          <div class="top-shadow"></div>
          <div class="dashed-line"></div>
          <div class="bottom-shadow"></div>
          <div class="rightCircle"></div>
        </div>

        <div class="page4" id="page4">
          <div class="page4Text">
            <div class="no1" id="no1">01</div>
            <div class="firstLine" id="firstLine">
              <div class="no1Line"></div>
              <div class="firstLineText">PROBLEM STATEMENT</div>
            </div>
            <div class="secLine" id="secLine">
              Trading, fundamentally a matter of buying low and selling high,
              poses intricate challenges:
            </div>
            <div class="thirdLine" id="thirdLine">
              <div class="third1">Entry and Exit Points</div>
              <div style="margin-top: 5px">
                Determining optimal buy and sell points.
              </div>
            </div>
            <div class="fourthLine" id="fourthLine">
              <div class="third1">Volume Decisions</div>
              <div style="margin-top: 5px">
                Deciding how much to trade at each point.
              </div>
            </div>
            <div class="fifthLine" id="fifthLine">
              <div class="third1">AI Integration</div>
              <div style="margin-top: 5px">
                Incorporating AI into trading environments without compromising
                security or efficiency.
              </div>
            </div>
          </div>
          <div class="page4Imgs">
            <img
              class="page4Img1"
              id="page4Img1"
              src="./assets/01top.png"
              alt=""
            />
            <img
              class="page4Img2"
              id="page4Img2"
              style="margin-top: 20px"
              src="./assets/01bottom.png"
              alt=""
            />
          </div>
        </div>
        <div class="page5" id="page5">
          <div class="page5Imgs">
            <img
              class="page5Img1"
              id="page5Img1"
              src="./assets/02top.png"
              alt=""
            />
            <img
              class="page5Img2"
              id="page5Img2"
              style="margin-top: 20px"
              src="./assets/02bottom.png"
              alt=""
            />
          </div>
          <div class="page5Text">
            <div class="no2" id="no2">02</div>
            <div class="page5firstLine" id="page5firstLine">
              <div class="page5no1Line"></div>
              <div class="page5firstLineText">CAPABILITIES</div>
            </div>
            <div class="page5thirdLine" id="page5thirdLine">
              <div class="page5third1">Algorithm Security</div>
              <div style="margin-top: 5px">
                Proprietary algorithms operate within a black-box environment,
                safeguarding the author’s strategies.
              </div>
            </div>
            <div class="page5fourthLine" id="page5fourthLine">
              <div class="page5third1">Dynamic Strategy Updates</div>
              <div style="margin-top: 5px">
                Continuous adaptation to the latest market conditions, ensuring
                strategies remain effective.
              </div>
            </div>
            <div class="page5fifthLine" id="page5fifthLine">
              <div class="page5third1">Real-Time Feedback</div>
              <div style="margin-top: 5px">
                AI delivers rapid responses to market fluctuations, enhancing
                decision-making.
              </div>
            </div>
          </div>
        </div>
        <div class="page6" id="page6">
          <div class="page6Text">
            <div class="no3" id="no3">03</div>
            <div class="page6firstLine" id="page6firstLine">
              <div class="page6no1Line"></div>
              <div class="page6firstLineText">CHALLENGES</div>
            </div>
            <div class="page6thirdLine" id="page6thirdLine">
              <div class="page6third1">Tool Limitations</div>
              <div style="margin-top: 5px">
                Current market tools often simplify the programming process but
                lack sophistication in guiding trading decisions.
              </div>
            </div>
            <div class="page6fourthLine" id="page6fourthLine">
              <div class="page6third1">Strategy Confidentiality</div>
              <div style="margin-top: 5px">
                Expert traders are reluctant to share their high-performance
                algorithms to maintain competitive advantage.
              </div>
            </div>
            <div class="page6fifthLine" id="page6fifthLine">
              <div class="page6third1">Retail Demand</div>
              <div style="margin-top: 5px">
                Retail investors seek low-risk, high-return products but face
                limited options.
              </div>
            </div>
          </div>
          <div class="page6Imgs">
            <img
              class="page6Img1"
              id="page6Img1"
              src="./assets/03top.png"
              alt=""
            />
            <img
              class="page6Img2"
              id="page6Img2"
              style="margin-top: 20px"
              src="./assets/03bottom.png"
              alt=""
            />
            <img
              class="page6Img3"
              id="page6Img3"
              style="margin-top: 20px"
              src="./assets/03middle.png"
              alt=""
            />
          </div>
        </div>
        <div class="page7" id="page7">
          <div class="page7Imgs">
            <img
              class="page7Img1"
              id="page7Img1"
              src="./assets/04top1.png"
              alt=""
            />
            <img
              class="page7Img2"
              id="page7Img2"
              style="margin-top: 20px"
              src="./assets/04top2.png"
              alt=""
            />
            <img
              class="page7Img3"
              id="page7Img3"
              style="margin-top: 20px"
              src="./assets/04top3.png"
              alt=""
            />
            <img
              class="page7Img4"
              id="page7Img4"
              style="margin-top: 20px"
              src="./assets/04top4.png"
              alt=""
            />
            <img
              class="page7Img5"
              id="page7Img5"
              style="margin-top: 20px"
              src="./assets/04bottom.png"
              alt=""
            />
          </div>
          <div class="page7Text">
            <div class="no4" id="no4">04</div>
            <div class="page7firstLine" id="page7firstLine">
              <div class="page7no1Line"></div>
              <div class="page7firstLineText">SOLUTIONS</div>
            </div>
            <div class="page7secondLine" id="page7secondLine">
              <div class="page7third1">Advanced Algorithms</div>
              <div style="position: absolute; top: 40%; left: 0">
                Built with world-class trading algorithms, Convergence ensures
                superior performance.
              </div>
            </div>
            <div class="page7thirdLine" id="page7thirdLine">
              <div class="page7third1">AI-Driven Optimization</div>
              <div style="position: absolute; top: 40%; left: 0">
                AI fine-tunes trading algorithms for maximum profit.
              </div>
            </div>
            <div class="page7fouthLine" id="page7fouthLine">
              <div class="page7third1">Secure Execution</div>
              <div style="position: absolute; top: 40%; left: 0">
                Fully Homomorphic Encryption (FHE) technology guarantees the
                confidentiality of trading bot code, preventing leaks.
              </div>
            </div>
            <div class="page7fifthLine" id="page7fifthLine">
              <div class="page7third1">User-Friendly Returns</div>
              <div style="position: absolute; top: 40%; left: 0">
                Provides users with one-click access to optimized trading
                returns.
              </div>
            </div>
          </div>
        </div>
        <div class="page8" id="page8">
          <div class="page8text" id="page8text">
            Convergence transforms the trading landscape by combining
            cutting-edge AI with robust security, delivering unparalleled
            trading efficiency and profitability.
          </div>
          <div class="page8line" id="page8line"></div>
        </div>
      </div>
      <div class="footer" id="footer">
        <div class="footerLeft">
          <img class="footerImg" src="./assets/copy.png" alt="" />
          <div class="footerText">AI-Enabled Trading Algorithm Platform</div>
        </div>
        <div class="footerText" style="margin-right: 4%">
          {{
            `Copyright ${new Date().getFullYear()} Convergence, Inc. Terms & Privacy`
          }}
        </div>
      </div>
    </div>
    <div v-else-if="currentTab === 1" class="product">
      <img
        class="productMainImg"
        src="./assets/product/CelestialBlue.png"
        alt=""
      />
      <img class="rectangle" src="./assets/product/Rectangle8.png" alt="" />
      <img class="productContent" src="./assets/product/Content.png" alt="" />
      <img class="ellipse1" src="./assets/product/Ellipse1.png" alt="" />
      <img class="ellipse2" src="./assets/product/Ellipse2.png" alt="" />
    </div>
    <div v-else-if="currentTab === 2" class="roadmap">
      <img class="roadmapImg" src="./assets/roadmap/roadmap.png" alt="" />
    </div>
  </div>
  <div v-else class="contain">
    <h5-header />
    <h5-content />
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import Slider from "./components/Slider.vue";
import H5Header from "./components/H5Header.vue";
import H5Content from "./components/H5Content.vue";
export default {
  name: "AppHome",
  components: {
    AppHeader,
    Slider,
    H5Header,
    H5Content,
  },
  data() {
    return {
      isFirstLoading: true,
      throttledHandleWheel: null,
      platfrom: "PC",
      scrollDirection: 0,
      pageName: 1,
      currentTab: 0, //当前tab页
    };
  },
  watch: {
    currentTab(newValue, oldValue) {
      console.log(oldValue);
      if (newValue === 0) {
        this.initPage(500);
      }
    },
    platfrom(newVal) {
      if (newVal === "PC") {
        location.reload();
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.detectDeviceType);
    if (this.platfrom === "PC") {
      // 首屏停留1500ms后，开始进行动画
      this.initPage(1500);
    }
  },

  // 创建并保存节流后的 handleWheel 函数
  created() {
    this.detectDeviceType();
    if (this.platfrom === "PC") {
      this.throttledHandleWheel = this.throttle(this.handleWheel, 200);
    }
  },
  beforeDestroy() {
    if (this.platfrom === "PC") {
      // 组件销毁前移除事件监听
      window.removeEventListener("wheel", this.throttledHandleWheel);
      window.removeEventListener("resize", this.detectDeviceType);
    }
  },
  methods: {
    initPage(time) {
      setTimeout(() => {
        let leftImgElement = document.getElementById("leftImg");
        let rightImgElement = document.getElementById("rightImg");
        leftImgElement.classList.add("move-leftImg");
        rightImgElement.classList.add("move-rightImg");
        let page2Element = document.getElementById("page2");
        page2Element.classList.add("page2Appear");
        this.isFirstLoading = false;
        this.pageName = 2;
        this.pageSwitch();
      }, time);
    },
    // 改变tab
    onChangeTab(tab) {
      this.currentTab = tab;
    },
    detectDeviceType() {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;
      let platform = "PC";
      // 判断是否是移动设备
      if (/windows phone/i.test(userAgent)) {
        platform = "Windows Phone";
      }
      if (/android/i.test(userAgent)) {
        platform = "Android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        platform = "iOS";
      }
      this.platfrom = platform;
    },
    // 在组件中使用节流函数
    pageSwitch() {
      window.addEventListener("wheel", (event) => {
        this.scrollDirection = Math.sign(event.deltaY);
        this.throttledHandleWheel(event);
      });
    },
    throttle(func, wait) {
      // 维护一个定时器
      let timeout;
      // 返回一个函数，这个函数会在一个时间间隔后执行
      return function () {
        // 上下文和参数
        const context = this;
        const args = arguments;
        // 如果定时器存在，则清除它
        if (timeout !== undefined) {
          clearTimeout(timeout);
        }
        // 设置一个新的定时器，该定时器到期后执行真正的函数
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },
    // 清空后，重置类型
    resetClassName(id, initClassNmme, currentClassName) {
      let element = document.getElementById(id);
      if (element && element.classList) {
        while (element.classList.length > 0) {
          element.classList.remove(element.classList.item(0));
        }
        if (initClassNmme) {
          element.classList.add(initClassNmme);
        }
        if (currentClassName) {
          element.classList.add(currentClassName);
        }
      }
    },
    // 清空当前样式，初始化目标页样式
    onChangePage(type) {
      console.log(type, this.pageName);
      if (
        (type === "2" && (this.pageName === 2 || this.pageName === 3)) ||
        type == this.pageName
      ) {
        return;
      } else {
        if (this.pageName === 4) {
          //当前在No1页
          if (type === "2") {
            // 跳转到第二页
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 3;
              this.handleWheel();
            }, 1500);
          } else if (type === "5") {
            //在No2页 当前页的下一页直接调用方法
            this.scrollDirection = 1;
            this.pageName = 4;
            this.handleWheel();
          } else if (type === "6") {
            this.scrollDirection = 1;
            this.pageName = 4;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel();
            }, 1500);
          } else if (type === "7") {
            this.scrollDirection = 1;
            this.pageName = 4;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel();
            }, 3000);
          }
        } else if (this.pageName === 2) {
          // 当前在第二页
          if (type === "4") {
            // 前往No1页
            this.scrollDirection = 1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 3;
              this.handleWheel(true);
            }, 1500);
          } else if (type === "5") {
            this.scrollDirection = 1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 3;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel(true);
            }, 3000);
          } else if (type === "6") {
            this.scrollDirection = 1;
            this.handleWheel(true);
          } else if (type === "7") {
            this.scrollDirection = 1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 3;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel(true);
            }, 3000);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel();
            }, 4500);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel();
            }, 6000);
          }
        } else if (this.pageName === 3) {
          // 当前在第三页
          if (type === "4") {
            this.scrollDirection = 1;
            this.handleWheel();
          } else if (type === "5") {
            this.scrollDirection = 1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel();
            }, 1500);
          } else if (type === "6") {
            this.scrollDirection = 1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel();
            }, 3000);
          } else if (type === "7") {
            this.scrollDirection = 1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel(true);
            }, 3000);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel();
            }, 4500);
          }
        } else if (this.pageName === 5) {
          // No2页
          if (type === "6") {
            this.scrollDirection = 1;
            this.handleWheel();
          } else if (type === "7") {
            this.scrollDirection = 1;
            this.pageName = 5;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel();
            }, 1500);
          } else if (type === "4") {
            this.scrollDirection = -1;
            this.handleWheel();
          } else if (type === "2") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 3;
              this.handleWheel();
            }, 3000);
          }
        } else if (this.pageName === 6) {
          if (type === "7") {
            this.scrollDirection = 1;
            this.handleWheel();
          } else if (type === "5") {
            this.scrollDirection = -1;
            this.handleWheel();
          } else if (type === "4") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel();
            }, 1500);
          } else if (type === "2") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel(true);
            }, 3000);
            setTimeout(() => {
              this.pageName = 3;
              this.handleWheel();
            }, 4500);
          }
        } else if (this.pageName === 7) {
          if (type === "5") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel();
            }, 1500);
          } else if (type === "4") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel();
            }, 3000);
          } else if (type === "2") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel(true);
            }, 3000);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel(true);
            }, 4500);
            setTimeout(() => {
              this.pageName = 3;
              this.handleWheel();
            }, 6000);
          } else if (type === "6") {
            this.scrollDirection = -1;
            this.handleWheel();
          }
        } else if (this.pageName == 8) {
          if (type === "7") {
            this.scrollDirection = -1;
            this.handleWheel();
          } else if (type === "6") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 7;
              this.handleWheel();
            }, 1500);
          } else if (type === "5") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 7;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel();
            }, 3000);
          } else if (type === "4") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 7;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel(true);
            }, 3000);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel();
            }, 4500);
          } else if (type === "3") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 7;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel(true);
            }, 3000);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel();
            }, 4500);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel();
            }, 6000);
          } else if (type === "2") {
            this.scrollDirection = -1;
            this.handleWheel(true);
            setTimeout(() => {
              this.pageName = 7;
              this.handleWheel(true);
            }, 1500);
            setTimeout(() => {
              this.pageName = 6;
              this.handleWheel(true);
            }, 3000);
            setTimeout(() => {
              this.pageName = 5;
              this.handleWheel();
            }, 4500);
            setTimeout(() => {
              this.pageName = 4;
              this.handleWheel();
            }, 6000);
            setTimeout(() => {
              this.pageName = 3;
              this.handleWheel();
            }, 7500);
          }
        }
      }
    },
    handleWheel(opacity) {
      if (this.currentTab !== 0) return;
      if (typeof opacity != "boolean") opacity = false;
      if (this.isFirstLoading) return;
      if (this.scrollDirection > 0) {
        let leftImgElement = document.getElementById("leftImg");
        let rightImgElement = document.getElementById("rightImg");
        let page3Element = document.getElementById("page3");
        let page3linesElement = document.getElementById("lines");
        let cardElement = document.getElementById("page2");
        // 三屏动画
        if (this.pageName === 2) {
          this.resetClassName("rightImg", "initRightImg");
          this.resetClassName("leftImg", "initLeftImg");
          this.resetClassName("page3", "page3");
          this.resetClassName("lines", "line-with-shadows");
          this.resetClassName("page2", "page2");

          cardElement.classList.add("fade-in");
          leftImgElement.classList.add("sacle-leftImg");
          rightImgElement.classList.add("sacle-rightImg");
          page3Element.classList.add("page3Appear");
          page3linesElement.classList.add("page2Appearline");
          this.pageName = 3;
          return;
        }

        let page4ImgElement1 = document.getElementById("page4Img1");
        let page4ImgElement2 = document.getElementById("page4Img2");
        let page4no1 = document.getElementById("no1");
        let page4firstLine = document.getElementById("firstLine");
        let page4secLine = document.getElementById("secLine");
        let page4thirdLine = document.getElementById("thirdLine");
        let page4fourthLine = document.getElementById("fourthLine");
        let page4fifthLine = document.getElementById("fifthLine");
        // 四屏动画
        if (this.pageName === 3) {
          this.resetClassName("page4", "page4");
          this.resetClassName("leftImg", "initLeftImg");
          this.resetClassName("rightImg", "initRightImg");
          this.resetClassName("page3", "page3");
          this.resetClassName("page4Img1", "page4Img1");
          this.resetClassName("page4Img2", "page4Img2");
          this.resetClassName("no1", "no1");
          this.resetClassName("firstLine", "firstLine");
          this.resetClassName("secLine", "secLine");
          this.resetClassName("thirdLine", "thirdLine");
          this.resetClassName("fourthLine", "fourthLine");
          this.resetClassName("fifthLine", "fifthLine");

          leftImgElement.classList.add("hide-leftImg");
          rightImgElement.classList.add("hide-rightImg");
          page3Element.classList.add("page3Hide");
          page3linesElement.classList.add("page3linesHide");
          page4ImgElement1.classList.add("appear-page4img1");
          page4ImgElement2.classList.add("appear-page4img2");
          page4no1.classList.add("appear-page4no1");
          page4firstLine.classList.add("appear-page4firstLine");
          page4secLine.classList.add("appear-page4secLine");
          page4thirdLine.classList.add("appear-page4thirdLine");
          page4fourthLine.classList.add("appear-page4fourthLine");
          page4fifthLine.classList.add("appear-page4fifthLine");
          this.pageName = 4;
          return;
        }
        // 五屏动画
        let page4Element = document.getElementById("page4");
        let page5ImgElement1 = document.getElementById("page5Img1");
        let page5ImgElement2 = document.getElementById("page5Img2");
        let page5no1 = document.getElementById("no2");
        let page5firstLine = document.getElementById("page5firstLine");
        let page5thirdLine = document.getElementById("page5thirdLine");
        let page5fourthLine = document.getElementById("page5fourthLine");
        let page5fifthLine = document.getElementById("page5fifthLine");
        if (this.pageName === 4) {
          this.resetClassName("page4", "page4");
          this.resetClassName("page5Img1", "page5Img1");
          this.resetClassName("page5Img2", "page5Img2");
          this.resetClassName("no2", "no2");
          this.resetClassName("page5firstLine", "page5firstLine");
          this.resetClassName("page5thirdLine", "page5thirdLine");
          this.resetClassName("page5fourthLine", "page5fourthLine");
          this.resetClassName("page5fifthLine", "page5fifthLine");

          page4Element.classList.add("page4-hide");
          page5ImgElement1.classList.add("appear-page5img1");
          page5ImgElement2.classList.add("appear-page5img2");
          page5no1.classList.add("appear-page5no1");
          page5firstLine.classList.add("appear-page5firstLine");
          page5thirdLine.classList.add("appear-page5thirdLine");
          page5fourthLine.classList.add("appear-page5fourthLine");
          page5fifthLine.classList.add("appear-page5fifthLine");
          this.pageName = 5;
          return;
        }
        // 六屏动画
        let page5Element = document.getElementById("page5");
        let page6ImgElement1 = document.getElementById("page6Img1");
        let page6ImgElement2 = document.getElementById("page6Img2");
        let page6ImgElement3 = document.getElementById("page6Img3");
        let page6no1 = document.getElementById("no3");
        let page6firstLine = document.getElementById("page6firstLine");
        let page6thirdLine = document.getElementById("page6thirdLine");
        let page6fourthLine = document.getElementById("page6fourthLine");
        let page6fifthLine = document.getElementById("page6fifthLine");
        if (this.pageName === 5) {
          this.resetClassName("page5", "page5");
          this.resetClassName("page6Img1", "page6Img1");
          this.resetClassName("page6Img2", "page6Img2");
          this.resetClassName("page6Img3", "page6Img3");
          this.resetClassName("no3", "no3");
          this.resetClassName("page6firstLine", "page6firstLine");
          this.resetClassName("page6thirdLine", "page6thirdLine");
          this.resetClassName("page6fourthLine", "page6fourthLine");
          this.resetClassName("page6fifthLine", "page6fifthLine");

          page5Element.classList.add("page5-hide");
          page6ImgElement1.classList.add("appear-page6img1");
          page6ImgElement2.classList.add("appear-page6img2");
          page6ImgElement3.classList.add("appear-page6img3");
          page6no1.classList.add("appear-page6no1");
          page6firstLine.classList.add("appear-page6firstLine");
          page6thirdLine.classList.add("appear-page6thirdLine");
          page6fourthLine.classList.add("appear-page6fourthLine");
          page6fifthLine.classList.add("appear-page6fifthLine");
          this.pageName = 6;
          this.resetClassName("footer", "footer");
          return;
        }
        // 七屏动画
        let page6Element = document.getElementById("page6");
        let page7ImgElement1 = document.getElementById("page7Img1");
        let page7ImgElement2 = document.getElementById("page7Img2");
        let page7ImgElement3 = document.getElementById("page7Img3");
        let page7ImgElement4 = document.getElementById("page7Img4");
        let page7ImgElement5 = document.getElementById("page7Img5");
        let page7no1 = document.getElementById("no4");
        let page7firstLine = document.getElementById("page7firstLine");
        let page7secondLine = document.getElementById("page7secondLine");
        let page7thirdLine = document.getElementById("page7thirdLine");
        let page7fourthLine = document.getElementById("page7fouthLine");
        let page7fifthLine = document.getElementById("page7fifthLine");
        let page8footer = document.getElementById("footer");
        if (this.pageName === 6) {
          this.resetClassName("page6", "page6");
          this.resetClassName("page7Img1", "page7Img1");
          this.resetClassName("page7Img2", "page7Img2");
          this.resetClassName("page7Img3", "page7Img3");
          this.resetClassName("page7Img4", "page7Img4");
          this.resetClassName("page7Img5", "page7Img5");
          this.resetClassName("no4", "no4");
          this.resetClassName("page7firstLine", "page7firstLine");
          this.resetClassName("page7secondLine", "page7secondLine");
          this.resetClassName("page7thirdLine", "page7thirdLine");
          this.resetClassName("page7fouthLine", "page7fouthLine");
          this.resetClassName("page7fifthLine", "page7fifthLine");

          page6Element.classList.add("page6-hide");
          page7ImgElement1.classList.add("appear-page7img1");
          page7ImgElement2.classList.add("appear-page7img2");
          page7ImgElement3.classList.add("appear-page7img3");
          page7ImgElement4.classList.add("appear-page7img4");
          page7ImgElement5.classList.add("appear-page7img5");
          page7no1.classList.add("appear-page7no1");
          page7firstLine.classList.add("appear-page7firstLine");
          page7secondLine.classList.add("appear-page7secondLine");
          page7thirdLine.classList.add("appear-page7thirdLine");
          page7fourthLine.classList.add("appear-page7fouthLine");
          page7fifthLine.classList.add("appear-page7fifthLine");
          this.resetClassName("footer", "footer");
          this.pageName = 7;
          return;
        }

        let page7Element = document.getElementById("page7");
        let page8text = document.getElementById("page8text");
        let page8Line = document.getElementById("page8line");
        if (this.pageName === 7) {
          this.resetClassName("page7", "page7");
          this.resetClassName("page8text", "page8text");
          this.resetClassName("page8line", "page8line");
          this.resetClassName("footer", "footer");

          page7Element.classList.add("page7-hide");
          page8text.classList.add("page8-text");
          page8Line.classList.add("page8-line");
          page8footer.classList.add("footershow");
          this.pageName = 8;
        }
      } else {
        console.log("滚动上", this.pageName);
        if (this.pageName === 3) {
          // 当前在第二页进行回滚到上一页
          let leftImgElement = document.getElementById("leftImg");
          let rightImgElement = document.getElementById("rightImg");
          let page3Element = document.getElementById("page3");
          let page3linesElement = document.getElementById("lines");
          let cardElement = document.getElementById("page2");

          this.resetClassName("rightImg", "initRightImg");
          this.resetClassName("leftImg", "initLeftImg");
          this.resetClassName("page3", "page3");
          this.resetClassName("lines", "line-with-shadows", "page2Appearline");

          cardElement.classList.add("cardPlayBack");
          rightImgElement.classList.add("sacleRightImgPlayBack");
          leftImgElement.classList.add("sacleLeftImgPlayBack");
          page3Element.classList.add("page3PlayBack");
          page3linesElement.classList.add("page3linePlayBack");
          this.pageName = 2;
          return;
        } else if (this.pageName === 4) {
          // 当前在第三页进行回滚到上一页
          this.resetClassName("lines", "line-with-shadows", "page2Appearline");
          let page4ImgElement1 = document.getElementById("page4Img1");
          let page4ImgElement2 = document.getElementById("page4Img2");
          let page4no1 = document.getElementById("no1");
          let page4firstLine = document.getElementById("firstLine");
          let page4secLine = document.getElementById("secLine");
          let page4thirdLine = document.getElementById("thirdLine");
          let page4fourthLine = document.getElementById("fourthLine");
          let page4fifthLine = document.getElementById("fifthLine");
          let leftImgElement = document.getElementById("leftImg");
          let rightImgElement = document.getElementById("rightImg");
          let page3Element = document.getElementById("page3");

          this.resetClassName("page4", "page4");

          leftImgElement.classList.add("hideLeftImgPlayBack");
          rightImgElement.classList.add("hideRightImgPlayBack");
          page3Element.classList.add("page3HidePlayBack");
          page4ImgElement1.classList.add("appearPage4img1PlayBack");
          page4ImgElement2.classList.add("appearPage4img2PlayBack");
          page4no1.classList.add("appearPage4no1PlayBack");
          page4firstLine.classList.add("appearPage4firstLinePlayBack");
          page4secLine.classList.add("appearPage4secLinePlayBack");
          page4thirdLine.classList.add("appearPage4thirdLinepalyBack");
          page4fourthLine.classList.add("appearPage4fourthLinePalyBack");
          page4fifthLine.classList.add("appearPage4fifthLinePalyBack");
          this.pageName = 3;
        } else if (this.pageName === 5) {
          // 当前在第四页进行回滚到上一页
          let page4Element = document.getElementById("page4");
          let page5ImgElement1 = document.getElementById("page5Img1");
          let page5ImgElement2 = document.getElementById("page5Img2");
          let page5no1 = document.getElementById("no2");
          let page5firstLine = document.getElementById("page5firstLine");
          let page5thirdLine = document.getElementById("page5thirdLine");
          let page5fourthLine = document.getElementById("page5fourthLine");
          let page5fifthLine = document.getElementById("page5fifthLine");

          this.resetClassName("page4", "page4");

          page4Element.classList.add("page4HidePlayBack");
          page5ImgElement1.classList.add("appearPage5img1PlayBack");
          page5ImgElement2.classList.add("appearPage5img2PlayBack");
          page5no1.classList.add("appearPage5no1PlayBack");
          page5firstLine.classList.add("appearPage5firstLinePlayBack");
          page5thirdLine.classList.add("appearPage5thirdLinePlayBack");
          page5fourthLine.classList.add("appearPage5fourthLinePlayBack");
          page5fifthLine.classList.add("appearPage5fifthLinePlayBack");
          this.pageName = 4;
        } else if (this.pageName === 6) {
          // 当前在第五页进行回滚到上一页
          let page5Element = document.getElementById("page5");
          let page6ImgElement1 = document.getElementById("page6Img1");
          let page6ImgElement2 = document.getElementById("page6Img2");
          let page6ImgElement3 = document.getElementById("page6Img3");
          let page6no1 = document.getElementById("no3");
          let page6firstLine = document.getElementById("page6firstLine");
          let page6thirdLine = document.getElementById("page6thirdLine");
          let page6fourthLine = document.getElementById("page6fourthLine");
          let page6fifthLine = document.getElementById("page6fifthLine");

          this.resetClassName("page5", "page5");
          this.resetClassName("page6Img1", "page6Img1");
          this.resetClassName("page6Img2", "page6Img2");
          this.resetClassName("page6Img3", "page6Img3");
          this.resetClassName("no3", "no3");
          this.resetClassName("page6firstLine", "page6firstLine");
          this.resetClassName("page6thirdLine", "page6thirdLine");
          this.resetClassName("page6fourthLine", "page6fourthLine");
          this.resetClassName("page6fifthLine", "page6fifthLine");

          page5Element.classList.add("page5HidePlayBack");
          page6ImgElement1.classList.add("appearPage6img1PlayBack");
          page6ImgElement2.classList.add("appearPage6img2PlayBack");
          page6ImgElement3.classList.add("appearPage6img3PlayBak");
          page6no1.classList.add("appearPage6no1PlayBack");
          page6firstLine.classList.add("appearPage6firstLinePlayBack");
          page6thirdLine.classList.add("appearPage6thirdLinePlayBack");
          page6fourthLine.classList.add("appearPage6fourthLinePlayBack");
          page6fifthLine.classList.add("appearPage6fifthLinePlayBack");
          this.pageName = 5;
          return;
        } else if (this.pageName === 7) {
          let page6Element = document.getElementById("page6");
          let page7ImgElement1 = document.getElementById("page7Img1");
          let page7ImgElement2 = document.getElementById("page7Img2");
          let page7ImgElement3 = document.getElementById("page7Img3");
          let page7ImgElement4 = document.getElementById("page7Img4");
          let page7ImgElement5 = document.getElementById("page7Img5");
          let page7no1 = document.getElementById("no4");
          let page7firstLine = document.getElementById("page7firstLine");
          let page7secondLine = document.getElementById("page7secondLine");
          let page7thirdLine = document.getElementById("page7thirdLine");
          let page7fourthLine = document.getElementById("page7fouthLine");
          let page7fifthLine = document.getElementById("page7fifthLine");

          this.resetClassName("page6", "page6");
          this.resetClassName("page7Img1", "page7Img1");
          this.resetClassName("page7Img2", "page7Img2");
          this.resetClassName("page7Img3", "page7Img3");
          this.resetClassName("page7Img4", "page7Img4");
          this.resetClassName("page7Img5", "page7Img5");
          this.resetClassName("no4", "no4");
          this.resetClassName("page7firstLine", "page7firstLine");
          this.resetClassName("page7secondLine", "page7secondLine");
          this.resetClassName("page7thirdLine", "page7thirdLine");
          this.resetClassName("page7fouthLine", "page7fouthLine");
          this.resetClassName("page7fifthLine", "page7fifthLine");

          page6Element.classList.add("page6HidePlayBack");
          page7ImgElement1.classList.add("appearPage7img1PlayBack");
          page7ImgElement2.classList.add("appearPage7img2PlayBack");
          page7ImgElement3.classList.add("appearPage7img3PlayBack");
          page7ImgElement4.classList.add("appearPage7img4PlayBack");
          page7ImgElement5.classList.add("appearPage7img5PlayBack");
          page7no1.classList.add("appearPage7no1PlayBack");
          page7firstLine.classList.add("appearPage7firstLinePlayBack");
          page7secondLine.classList.add("appearPage7secondLinePlayBack");
          page7thirdLine.classList.add("appearPage7thirdLinePlayBack");
          page7fourthLine.classList.add("appearPage7fouthLinePlayBack");
          page7fifthLine.classList.add("appearPage7fifthLinePlayBack");
          this.resetClassName("footer", "footer");
          this.pageName = 6;
          return;
        } else if (this.pageName === 8) {
          let page7Element = document.getElementById("page7");
          let page8text = document.getElementById("page8text");
          let page8Line = document.getElementById("page8line");
          let page8footer = document.getElementById("footer");
          this.resetClassName("footer", "footer");
          this.resetClassName("page7", "page7");
          this.resetClassName("page8text", "page8text");
          this.resetClassName("page8line", "page8line");

          page7Element.classList.add("page7HidePlayBack");
          page8text.classList.add("page8TextPlayBack");
          page8Line.classList.add("page8LinePlayBack");
          page8footer.classList.add("footerhide");
          this.pageName = 7;
        }
      }
    },
  },
};
</script>

<style scoped>
@import "./app.css";
</style>
