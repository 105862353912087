<template>
  <div class="headerBox">
    <div class="header" id="header">
      <div class="btn logoBtn">
        <img class="logo" src="../assets//White_400.png" alt="" />
        <span>CONVERGENCE</span>
      </div>
      <div style="width: 600px; display: flex; justify-content: space-evenly">
        <span
          @click="changeTab(item.id)"
          v-for="(item, index) in tabList"
          :class="['tabItems', index === currentTab ? 'active' : '']"
          :key="item.id"
          >{{ item.name }}</span
        >
      </div>
      <div class="rightHeader">
        <div class="btn">LAUNCH APP</div>
        <img src="../assets/arrow1.png" style="margin-left: 10px" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  props: {
    currentTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabList: [
        {
          id: 0,
          name: "HOME",
        },
        {
          id: 1,
          name: "PRODUCT",
        },
        {
          id: 2,
          name: "ROADMAP",
        },
        {
          id: 3,
          name: "DOCS",
        },
      ],
    };
  },
  mounted() {
    // 首屏停留1500ms后，开始进行动画
    setTimeout(() => {
      let leftImgElement = document.getElementById("header");
      leftImgElement.classList.add("headerAppeaar");
    }, 1500);
  },
  methods: {
    changeTab(id) {
      if (id === 3) return;
      this.$emit("onChangeTab", id);
    },
  },
};
</script>

<style scoped>
.headerBox {
  position: relative;
  padding-top: 3%;
  z-index: 1000;
}
.rightHeader {
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 0;
}
.headerAppeaar {
  animation: headerOpacity 1.5s ease-out forwards;
}
/* 首屏左图片动画 */
@keyframes headerOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 4%;
  margin-right: 4%;
  opacity: 0;
}
.btn {
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.logoBtn {
  display: flex;
  align-items: center;
}
.tabItems {
  color: rgb(153, 150, 150);
  font-weight: bold;
  cursor: pointer;
}
.logo {
  height: 20px;
  width: 20px;
  margin-right: 6px;
}

.active {
  color: white;
}
</style>
